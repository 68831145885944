body {
  font-family: 'Georgia', serif;
  margin: 0;
  padding: 0;
  background-color: #faf3e0;
  color: #3d3d3d;
}

.app {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  /* margin-left: 230px; Leave space for the navbar */
}

.banner {
  height: 100vh; /* Full viewport height */
  display: flex; /* Activate flexbox */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  flex-direction: column; /* Stack items vertically */
  gap: 20px; /* Add spacing between the header and the button */
}

.header {
  max-width: 650px;
  text-align: center;
  margin-bottom: 10vh;
}
.scroll-down-btn {
  position: absolute;
  bottom: 15vh;
  left: 50%;
  transform: translateX(-50%);
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: #5a3e36;
  animation: bounce 2s infinite;
}



.scroll-down-btn:hover {
  color: #b62c2c;
}

@keyframes bounce {
  0%, 100% {
    transform: translate(-50%, 0);
  }
  50% {
    transform: translate(-50%, 10px);
  }
}


.header-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
}

.header-title {
  font-size: 3rem;
  color: #5a3e36;
  margin: 0;
}

.header .logo {
  width: 40px;
  height: 40px;
}

.footer .logo {
  width: 30px;
  height: 30px;
}


.header h2 {
  font-size: 0.95rem;
  color: #7d6b64;
}

.header p {
  font-size: 0.9rem;
  color: #7d6b64;
}

/* .photo-grid {
  margin-bottom: 30px;
  border: 1px solid #ddd;
  padding: 5vh 5vw 7vh 5vw;
  border-radius: 20px;
  background: white
} */


.photo-grid {
  margin-bottom: 30px;
  padding: 5vh 5vw 7vh 5vw;
  border-radius: 20px;
  background: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}


.photo-grid h3 {
  text-align: left;
  margin-bottom: 10px;
  margin-top: 0px;
  color: #333;
}

.photo-grid-images {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 15px;
  position: relative;
}

.photo-grid-image {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  border-radius: 5px;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.photo-grid-image:hover {
  transform: scale(1.05); /* Slight zoom effect on hover */
  z-index: 1; /* Make hovered image appear above others */
}

.footer {
  text-align: center;
  margin-top: 8vh;
  margin-bottom: 5vh;

  font-size: 0.7rem;
  color: #7d6b64;
}

.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
}

.modal img {
  max-width: 90%;
  max-height: 80%;
  border-radius: 10px;
}

.modal.show {
  display: flex;
}

.sticky-navbar {
  text-align: center;
  position: fixed;
  top: 50px; /* Adjust vertical positioning */
  left: 10px; /* Adjust horizontal positioning */
  max-height: 90vh; /* Prevent it from extending too far */
  overflow-y: auto; /* Allow scrolling */
  padding: 10px;
  /* background-color: rgba(255, 255, 255, 0.6); */
  border-radius: 10px;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);  */
  z-index: 1000;
  width: 100px; /* Keep the navbar compact */
}

.sticky-navbar h3 {
  font-size: 1rem;
  font-weight: bold;
  color: #5a3e36;
  margin-bottom: 10px;
}

.navbar-links {
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar-links li {
  margin-bottom: 10px;
}

.navbar-links button {
  background: none;
  border: none;
  color: #5a3e36;
  font-size: 0.9rem;
  text-align: left;
  width: 100%;
  padding: 5px;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.3s;
}

.navbar-links button:hover {
  color: #cb0505;
}

a {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit the text color from the parent element */
  transition: color 0.2s ease; /* Add a subtle transition for hover effects */
}

a:hover {
  color: #cb0505; /* Change color on hover (optional, you can adjust to fit your theme) */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  text-align: center;
}


@media (max-width: 1024px) {
  .sticky-navbar {
    display: none; /* Hide the navbar on screens smaller than 768px */
  }


}

@media (max-width: 726px) {
  .header-title {
    font-size: 1.8rem;
  }
  
  .header p {
    font-size: 0.7rem;
  }
  


  
}

@media screen and (max-width: 600px) {
  .scroll-down-btn {
    bottom: 8vh;
  }
}